import 'normalize.css';
import { Global } from '@emotion/core';
import ReactGA from 'react-ga';

import React from 'react';
import { Provider } from 'react-redux';
import { Layout } from 'components';
import { globals } from 'style';

import createStore from './src/redux-store/store/configureStore';
import { FirebaseProvider } from './src/components/FirebaseProvider';

/**
 *
 * @param element root node element
 * @description Used for wrapping the root node with all of the providers that should be available in the app and avoid remounting
 */
export const wrapRootElement = ({ element }) => {
  const store = createStore();

  return (
    <Provider store={store}>
      <FirebaseProvider>
        <Global styles={globals} />
        {element}
      </FirebaseProvider>
    </Provider>
  );
};

/**
 *
 * @param element current page element
 * @param props gatsby injected props such as location
 * @description Used for wrapping all pages inside a shared layout. Layout component renders the content partially inside the <main /> html tag.
 */
export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

/**
 *
 * @param location current location from
 * @description Used for initializing Google Analytics and updating the page views on route change
 */

ReactGA.initialize(process.env.GATSBY_GA_KEY);

export const onRouteUpdate = ({ location }) => {
  ReactGA.pageview(location.pathname + location.search);
};
