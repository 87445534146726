import React, { Fragment } from 'react';
import { loading } from 'style';

interface Props {
  isLoading: boolean;
}

export const Loading: React.FC<Props> = ({ isLoading, children }) =>
  isLoading ? (
    <div css={loading.spinnerWrapper}>
      <div css={loading.spinner} />
    </div>
  ) : (
    <Fragment>{children}</Fragment>
  );
