import { css, keyframes } from '@emotion/core';

const spinnerAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const spinnerWrapper = css`
  width: 100%;
  height: calc(100vh - 72px - 416px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spinner = css`
  position: relative;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 2px solid var(--color-text);
  border-right: 2px solid var(--color-text);
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  transform: translateZ(0);
  animation: ${spinnerAnimation} 0.5s infinite linear;

  &,
  &::after {
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
`;

export const loading = {
  spinnerWrapper,
  spinner,
};
