import { Pagination } from 'components';
import { Link, PageProps } from 'gatsby';
import { Article } from 'models';
import React, { useEffect, useState } from 'react';
import * as articlelisting from 'style/articlelisting';
import * as utils from 'style/utils';
import { slugify } from 'utils';

import { Loading } from './Loading';

interface Props extends PageProps {
  articleList: Article[];
  articlesAreChanging: boolean;
}

const ArticleListing: React.FC<Props> = ({
  articleList,
  articlesAreChanging,
  ...rest
}) => {
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    setArticles(articleList);
  }, [articleList]);

  return (
    <Loading isLoading={articlesAreChanging}>
      <div css={utils.wrapper}>
        <div css={articlelisting.layout}>
          {articles.length === 0 && (
            <div>
              <p>There are currently no articles in this category</p>
            </div>
          )}

          {articles.map((article) => (
            <article key={article._meta.uid}>
              <div>
                <Link
                  to={`/article/${article._meta.uid}`}
                  css={articlelisting.header}
                >
                  <h2 css={articlelisting.title} data-comp="title">
                    {article.title[0].text}
                  </h2>
                  <img src={article.image.url} alt={article.image.alt} />
                </Link>
                <p css={[utils.typeMicro, utils.spacerTopSm]}>
                  Posted in{' '}
                  <Link
                    to={`/category/${slugify(article.category)}`}
                    css={articlelisting.link}
                  >
                    {article.category}
                  </Link>{' '}
                  {article.posted_by && `by ${article.posted_by[0].text}`}
                </p>
              </div>
            </article>
          ))}
        </div>
        <Pagination {...rest} />
      </div>
    </Loading>
  );
};

export default ArticleListing;
