/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useSiteData } from 'hooks';
import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  image?: string;
  description?: string;
  lang?: string;
  meta?: Array<{
    name?: string;
    property: string;
    content: string;
  }>;
  title: string;
  url?: string;
  siteName?: string;
  creator?: string;
}

export const SEO: React.FC<Props> = ({
  description = 'People at Prototyp store their inspirations in this museum of beautiful creations. Design, art branding, and digital marvels that will inspire you.',
  lang = 'en',
  meta = [],
  title,
  image,
  url,
  creator,
  siteName = 'Relay by PROTOTYP',
}) => {
  const { siteUrl, imageSrc } = useSiteData();
  const defaultSeoImage = siteUrl + imageSrc;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          rel: 'canonical',
          href: url,
        },
      ]}
      title={title}
      titleTemplate={`%s | Relay`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `image`,
          content: image || defaultSeoImage,
        },
        {
          property: `og:image`,
          content: image || defaultSeoImage,
        },
        {
          property: `twitter:card`,
          content: 'summary_large_image',
        },
        {
          property: `twitter:title`,
          content: title,
        },
        {
          property: `twitter:description`,
          content: description,
        },
        {
          property: `twitter:creator`,
          content: creator,
        },
        {
          property: `twitter:image`,
          content: image || defaultSeoImage,
        },
        {
          property: `og:site_name`,
          content: siteName,
        },
        ...meta,
      ]}
    />
  );
};
