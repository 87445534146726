import { navigate, useLocation } from '@reach/router';
import { parse } from 'querystring';
import React, { useEffect } from 'react';

export const SEOInterceptor: React.FC = () => {
  const { search } = useLocation();
  const { seoredirect } = parse(search.slice(1));

  useEffect(() => {
    if (seoredirect && typeof seoredirect === 'string') {
      navigate(seoredirect);
    }
  }, [seoredirect]);

  return null;
};
