import { css } from '@emotion/core';

export const wrapper = css`
  padding-top: 10vh;
  margin-top: 10vh;
  border-top: 1px solid hsla(0, 0%, 0%, 0.1);
`;

export const subscribeLayout = css`
  @media (min-width: 70em) {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const subscribeLayoutHeading = css`
  @media (max-width: 70em) {
    margin-bottom: 60px;
  }
  @media (min-width: 70em) {
    grid-column: 1 / 4;
  }
`;

export const subscribeLayoutContent = css`
  @media (min-width: 70em) {
    grid-column: 5 / 13;
  }

  @media (min-width: 100em) {
    grid-column: 5 / 10;
  }
`;

export const inputNewsletter = css`
  padding: 20px;
  width: 100%;
  border: 1px solid hsla(0, 0%, 0%, 0.1);

  &::placeholder {
    color: hsla(0, 0%, 0%, 0.4);
  }

  & + & {
    border-top: 0;
  }
`;

export const inputNewsletterSubscribe = css`
  border: 0;

  display: block;
  width: 100%;
  padding: 20px 40px;
  margin-bottom: 20px;

  background: hsla(0, 0%, 0%, 0.9);
  color: white;
`;

export const inputNewsletterGDPR = css`
  padding: 20px;
  width: 100%;
  border-left: 1px solid hsla(0, 0%, 0%, 0.1);
  border-right: 1px solid hsla(0, 0%, 0%, 0.1);
`;

export const inputNewsletterAgree = css`
  display: inline-flex;
`;

export const inputNewsletterAgreeSpan = css`
  padding-left: 10px;
`;
