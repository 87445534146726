import { css } from '@emotion/core';

export const header = css`
  position: relative;
  padding: 5vh 0 5vh 0;
`;

export const link = css`
  font-size: 24px;
  font-family: var(--ff-sans);
  font-weight: 700;
  letter-spacing: -0.03em;
`;

export const headerStyles = {
  header,
  link,
};
