import { Article } from 'models';

import { ArticlesActions } from './actions';
import { ArticlesTypes } from './types';

export interface ArticleState {
  articlesAreChanging: boolean;
  error?: string | string[];
  articles: Article[];
  categories: string[];
}

const INITIAL_STATE: ArticleState = {
  articlesAreChanging: true,
  error: undefined,
  articles: [],
  categories: [
    'Websites',
    'Product Design',
    'Illustration',
    'Architecture',
    'Branding',
    'Graphic design',
  ],
};

export default (
  state: ArticleState = INITIAL_STATE,
  action: ArticlesActions,
) => {
  switch (action.type) {
    case ArticlesTypes.AddLoading:
      return {
        ...state,
        articlesAreChanging: true,
        error: undefined,
      };

    case ArticlesTypes.AddArticles:
      return {
        ...state,
        articlesAreChanging: false,
        error: undefined,
        articles: action.payload.articles,
      };

    case ArticlesTypes.AddCategories:
      return {
        ...state,
        articlesAreChanging: false,
        error: undefined,
        categories: action.payload.categories,
      };

    case ArticlesTypes.AddError:
      return {
        ...state,
        articlesAreChanging: false,
        error: action.payload.error,
      };

    default:
      return state || INITIAL_STATE;
  }
};
