import { css } from '@emotion/core';

/* Wrapper */

export const wrapper = css`
  margin: 0 auto;
  padding: 0 24px;

  @media screen and (min-width: 48em) {
    padding: 0 40px;
  }
`;

/* Spacers */

export const spacerBottomSm = css`
  margin-bottom: 12px;
`;

export const spacerBottomMd = css`
  margin-bottom: 20px;
`;

export const spacerBottomLg = css`
  margin-bottom: 28px;
`;

export const spacerTopSm = css`
  margin-top: 12px;
`;

export const spacerTopMd = css`
  margin-top: 20px;
`;

export const spacerTopLg = css`
  margin-top: 28px;
`;

/* Opacity */
export const opacity80 = css`
  opacity: 0.8;
`;

export const opacity60 = css`
  opacity: 0.6;
`;

export const opacity40 = css`
  opacity: 0.4;
`;

/* Typography */

export const fontFamilySans = css`
  font-family: var(--ff-sans);
`;

export const fontFamilySerif = css`
  font-family: var(--ff-serif);
`;

export const typeUpperExtended = css`
  text-transform: uppercase;
  letter-spacing: 0.2em;
`;

/* Basic type sizes */

export const typeMicro = css`
  font-size: 12px;
`;

export const typeSmall = css`
  font-size: 14px;
`;

export const typeBase = css`
  font-size: 16px;
`;

/* Fluid typography */

export const fluidTypeSmall = css`
  font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1600 - 320)));
`;

export const fluidTypeBase = css`
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1600 - 320)));
`;

export const fluidTypeMedium = css`
  font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1600 - 320)));
`;

export const fluidTypeLarge = css`
  font-size: calc(20px + (31 - 20) * ((100vw - 320px) / (1600 - 320)));
`;

export const fluidTypeXLarge = css`
  font-size: calc(25px + (39 - 25) * ((100vw - 320px) / (1600 - 320)));
`;

export const fluidTypeXXLarge = css`
  font-size: calc(31px + (49 - 31) * ((100vw - 320px) / (1600 - 320)));
`;

export const fluidTypeXXXLarge = css`
  font-size: calc(39px + (61 - 39) * ((100vw - 320px) / (1600 - 320)));
`;

/* Buttons */

export const btn = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-right: 32px;
  padding-left: 32px;
  min-height: 40px;
  font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1600 - 320)));
  transition: all 0.2s ease-out;
`;

export const btnPrimary = css`
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  color: hsla(0, 0%, 0%, 0.7);

  &:hover,
  &:focus {
    border-color: hsla(0, 0%, 0%, 0.7);
  }
`;
