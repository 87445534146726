import React from 'react';

interface Props {
  siteUrl: string;
  title: string;
  image: string;
  author: string;
  datePublished: string;
  dateModified: string;
  pathname: string;
}

export const GoogleRichSnippet: React.FC<Props> = ({
  title,
  image,
  author,
  datePublished,
  dateModified,
  siteUrl,
  pathname,
}) => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${siteUrl}${pathname}`,
    },
    headline: title,
    image: [`${siteUrl}${image}`],
    datePublished: datePublished,
    dateModified: dateModified,
    author: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Relay by PROTOTYP',
      logo: {
        '@type': 'ImageObject',
        url: `${siteUrl}/images/prototyp.png`,
      },
    },
  };
  return (
    <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
  );
};
