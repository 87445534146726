import { css } from '@emotion/core';

export const categoryLinksWrapper = css`
  position: sticky;
  top: 0;
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 40px;
  background: white;
  z-index: 2;

  @media screen and (min-width: 48em) {
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 5vh;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: hsla(0, 0%, 0%, 0.1);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
  }
`;

export const categoryLinksScroller = css`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
`;

export const categoryLinks = css`
  flex: 0 0 auto;

  display: inline-flex;
  position: relative;
  margin-right: 16px;
  padding: 24px 0;

  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  color: hsla(0, 0%, 0%, 0.5);
  transition: all 0.2s ease-out;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    z-index: 5;
    left: 0;
    width: 100%;
    height: 1px;
    background: #000;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s ease-out;
  }

  &:hover,
  &:focus {
    color: hsla(0, 0%, 0%, 0.8);
  }
`;

export const categoryLinkActive = css`
  color: hsla(0, 0%, 0%, 1);

  &:after {
    transform: scale3d(1, 1, 1);
  }
`;
