import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { PrismicLink } from 'apollo-link-prismic';
import gql from 'graphql-tag';
import { AppDispatch } from 'redux-store/store';

import { ArticlesActions } from './actions';

const client = new ApolloClient({
  link: PrismicLink({
    uri: process.env.GATSBY_PRISMIC_URI,
  }),
  cache: new InMemoryCache(),
});

const getAllArticles = (page = '', direction = '', category?: string) => async (
  dispatch: AppDispatch,
) => {
  dispatch(ArticlesActions.addLoading());

  const { data, errors } = await client.query({
    variables: {
      before: direction === 'previous' ? page : '',
      after: direction === 'next' ? page : '',
      category,
    },
    query: gql`
      query articles($before: String!, $after: String!, $category: String) {
        allArticles(
          before: $before
          after: $after
          lang: "en-us"
          where: { category: $category }
          sortBy: meta_firstPublicationDate_DESC
        ) {
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
          edges {
            cursor
            node {
              _meta {
                uid
                tags
                firstPublicationDate
                lastPublicationDate
              }
              title
              category
              image
              image
              category
              posted_by
              content
              link_to {
                ... on _ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    `,
  });

  if (errors) {
    dispatch(ArticlesActions.addError(errors.map((error) => error.message)));
    return;
  }

  dispatch(
    ArticlesActions.addArticles(
      data.allArticles.edges.map(({ node, cursor }: any) => ({
        ...node,
        cursor,
        pageInfo: data.allArticles.pageInfo,
      })),
    ),
  );

  return;
};

const getArticle = (uid: string) => async (dispatch: AppDispatch) => {
  dispatch(ArticlesActions.addLoading());

  const { data, errors } = await client.query({
    variables: {
      uid,
    },
    query: gql`
      query article($uid: String!) {
        article(lang: "en-us", uid: $uid) {
          _meta {
            uid
            tags
            firstPublicationDate
            lastPublicationDate
          }
          title
          category
          image
          image
          category
          posted_by
          content
          link_to {
            ... on _ExternalLink {
              url
            }
          }
        }
      }
    `,
  });

  if (errors) {
    dispatch(ArticlesActions.addError(errors.map((error) => error.message)));
    return;
  }

  dispatch(ArticlesActions.addArticles([data.article]));

  return;
};

export const ArticlesThunks = {
  getAllArticles,
  getArticle,
};
