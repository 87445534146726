enum types {
  AddLoading = 'articles/ADD_LOADING',
  AddArticles = 'articles/ADD_ARTICLES',
  AddCategories = 'articles/ADD_CATEGORIES',
  AddError = 'articles/ADD_ERROR',
}

export const ArticlesTypes = {
  ...types,
};
