import css from '@emotion/css';

export const paginationWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;

  a {
    min-width: 200px;
    margin: 0 32px;
  }
`;
