import { graphql, useStaticQuery } from 'gatsby';

interface SiteData {
  imageSrc: string;
  siteUrl: string;
}

export function useSiteData(): SiteData {
  const { imageFile, site } = useStaticQuery(
    graphql`
      query ImageQuery {
        imageFile: file(relativePath: { eq: "logo.png" }) {
          image: childImageSharp {
            fixed(width: 850, height: 450) {
              src
            }
          }
        }

        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );

  const {
    image: {
      fixed: { src: imageSrc },
    },
  } = imageFile;

  const {
    siteMetadata: { siteUrl },
  } = site;

  return { imageSrc, siteUrl };
}
