import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore as reduxCreateStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { articlesReducer } from 'redux-store/articles';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { ApplicationState } from '../models';

const composeEnhancers = composeWithDevTools({});

const rootReducer = {
  articles: articlesReducer,
};

const createStore = () =>
  reduxCreateStore(
    combineReducers(rootReducer),
    {},
    composeEnhancers(applyMiddleware(thunk)),
  );

export default createStore;
export type AppDispatch = ThunkDispatch<ApplicationState, any, AnyAction>;
