import { css } from '@emotion/core';
import { Link, PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, ApplicationState, ArticlesThunks } from 'redux-store';
import * as category from 'style/category';
import * as footer from 'style/footer';
import * as subscribe from 'style/subscribe';
import * as utils from 'style/utils';
import { slugify } from 'utils';

import { Header } from './header';

export const Layout: React.FC<PageProps> = ({ children, location }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { pathname, search } = location;
  const [, firstSlug, secondSlug] = pathname.split('/');
  const isCategory = firstSlug === 'category';
  const categorySlug = isCategory && secondSlug;

  const { categories, articles } = useSelector(
    (state: ApplicationState) => state.articles,
  );

  const currentCategory = categories.find(
    (category) => slugify(category) === categorySlug,
  );

  const { page = '', direction = '' } = queryString.parse(search);

  const currentPage = typeof page === 'string' ? page : page?.join();
  const currentDirection =
    typeof direction === 'string' ? direction : direction?.join();

  useEffect(() => {
    /**
     * If user has landed on a category page
     */
    if (firstSlug === 'category' || !firstSlug) {
      dispatch(
        ArticlesThunks.getAllArticles(
          currentPage,
          currentDirection,
          currentCategory,
        ),
      );
    }

    /**
     * If user has landed on an article page
     */
    if (firstSlug === 'article') {
      dispatch(ArticlesThunks.getArticle(secondSlug));
    }
  }, [
    dispatch,
    currentPage,
    currentDirection,
    currentCategory,
    firstSlug,
    secondSlug,
  ]);

  function determineActive(tabSlug: string) {
    if (isCategory && secondSlug === tabSlug) {
      return true;
    }

    const currentArticle = articles.find(
      (article) => article._meta.uid === secondSlug,
    );

    if (
      firstSlug === 'article' &&
      currentArticle &&
      slugify(currentArticle.category) === tabSlug
    ) {
      return true;
    }

    return false;
  }

  return (
    <>
      <Header siteTitle="Relay" />

      <nav css={category.categoryLinksWrapper}>
        <div css={category.categoryLinksScroller}>
          <Link
            css={[
              category.categoryLinks,
              !pathname.split('/')[1] && category.categoryLinkActive,
            ]}
            to="/"
          >
            All
          </Link>
          {categories.map((item) => (
            <Link
              css={[
                category.categoryLinks,
                determineActive(slugify(item) || '') &&
                  category.categoryLinkActive,
              ]}
              key={slugify(item)}
              to={`/category/${slugify(item)}`}
            >
              {item}
            </Link>
          ))}
        </div>
      </nav>

      <main>{children}</main>
      <section css={subscribe.wrapper}>
        <div css={utils.wrapper}>
          <div css={subscribe.subscribeLayout}>
            <div css={subscribe.subscribeLayoutHeading}>
              <h1 css={utils.spacerBottomLg}>
                Subscribe to Relay weekly digest.
              </h1>
              <p css={utils.spacerBottomSm}>
                Get a weekly roundup of all Relay content directly in your
                inbox. One e-mail per week packed with hand picked content by
                our team of designers, developers and product managers.
              </p>
              <p
                css={css`
                  opacity: 0.6;
                `}
              >
                <small>
                  Your data is yours to keep. The e-mail address you provide
                  will exclusively be used to send you weekly Relay newsletters,
                  nothing more, ever. You can unsubscribe at any time by
                  clicking the link in the footer of our emails.
                </small>
              </p>
            </div>
            <div css={subscribe.subscribeLayoutContent}>
              <form
                action="https://digital.us19.list-manage.com/subscribe/post?u=b5e6f2147bd0d589ab2183654&amp;id=73a0b2fd84"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  <div>
                    <input
                      type="text"
                      defaultValue=""
                      name="FNAME"
                      placeholder="Your name"
                      css={subscribe.inputNewsletter}
                      id="mce-FNAME"
                    />
                    <input
                      type="email"
                      defaultValue=""
                      name="EMAIL"
                      css={subscribe.inputNewsletter}
                      placeholder="Your e-mail (required)"
                      id="mce-EMAIL"
                    />
                  </div>
                  <div id="mergeRow-gdpr" css={subscribe.inputNewsletterGDPR}>
                    <div className="content__gdpr">
                      <p css={utils.spacerBottomMd}>
                        Do you agree that we use your e-mail address to send you
                        weekly Relay digests?
                      </p>
                      <p css={[utils.spacerBottomMd, `opacity: 0.6;`]}>
                        <small>
                          We use Mailchimp as our marketing platform. By
                          clicking "Subscribe" to subscribe, you acknowledge
                          that your information will be transferred to Mailchimp
                          for processing.{' '}
                          <a href="https://mailchimp.com/legal/">
                            Learn more about Mailchimp's privacy practices here.
                          </a>
                        </small>
                      </p>
                      <label className="checkbox subfield" htmlFor="gdpr_70020">
                        <input
                          type="checkbox"
                          id="gdpr_70020"
                          name="gdpr[70020]"
                          value="Y"
                          css={subscribe.inputNewsletterAgree}
                        />
                        <span css={subscribe.inputNewsletterAgreeSpan}>
                          Yes, I agree
                        </span>
                      </label>
                    </div>
                  </div>
                  <div id="mce-responses" className="clear">
                    <div
                      className="response"
                      id="mce-error-response"
                      css={css`
                        display: none;
                      `}
                    ></div>
                    <div
                      className="response"
                      id="mce-success-response"
                      css={css`
                        display: none;
                      `}
                    ></div>
                  </div>
                  <div
                    css={css`
                      position: absolute;
                      left: -5000px;
                    `}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_b5e6f2147bd0d589ab2183654_73a0b2fd84"
                      tabIndex={-1}
                      defaultValue=""
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      css={subscribe.inputNewsletterSubscribe}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer css={footer.wrapper}>
        <div css={utils.wrapper}>
          <p css={utils.typeMicro}>
            Relay is a cool gadget made by{' '}
            <a
              href="https://prototyp.digital"
              css={css`
                letter-spacing: 0.1em;
              `}
            >
              PROTOTYP
            </a>{' '}
            in the year {new Date().getFullYear()}. Our main goal is world
            domination by link sharing.
          </p>
        </div>
      </footer>
    </>
  );
};
