import { Article } from 'models';
import { ActionUnion, createAction } from 'redux-store';

import { ArticlesTypes } from './types';

const AddActions = {
  addLoading: () => createAction(ArticlesTypes.AddLoading),
  addArticles: (articles: Article[]) =>
    createAction(ArticlesTypes.AddArticles, { articles }),
  addCategories: (categories: string[]) =>
    createAction(ArticlesTypes.AddCategories, { categories }),
  addError: (error: string | string[]) =>
    createAction(ArticlesTypes.AddError, { error }),
};

export const ArticlesActions = {
  ...AddActions,
};

export type ArticlesActions = ActionUnion<typeof ArticlesActions>;
