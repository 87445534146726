import { Link, PageProps } from 'gatsby';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux-store';
import * as pagination from 'style/pagination';
import * as utils from 'style/utils';

export const Pagination: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location;
  const [, firstSlug] = pathname.split('/');
  const isCategory = firstSlug === 'category';
  const { articles } = useSelector((state: ApplicationState) => state.articles);

  if (!isCategory && firstSlug) {
    return null;
  }

  return (
    <nav css={pagination.paginationWrapper}>
      {articles[0]?.pageInfo?.hasPreviousPage && (
        <Link
          css={[utils.btn, utils.btnPrimary]}
          to={`?direction=previous&page=${articles[0]?.cursor}`}
        >
          Previous page
        </Link>
      )}
      {articles[articles.length - 1]?.pageInfo?.hasNextPage && (
        <Link
          css={[utils.btn, utils.btnPrimary]}
          to={`?direction=next&page=${articles[articles.length - 1]?.cursor}`}
        >
          Next page
        </Link>
      )}
    </nav>
  );
};
