import { css } from '@emotion/core';

export const layout = css`
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr;
  grid-auto-columns: min-content;
  animation: ListingFadeIn 0.6s ease-out forwards;
  opacity: 0;

  @media screen and (min-width: 50em) {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }

  @keyframes ListingFadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const header = css`
  display: block;

  &:hover,
  &:focus {
    [data-comp='title'] {
      background-size: 100% 2px;
    }
  }
`;

export const title = css`
  font-size: 32px;
  font-family: var(--ff-serif);
  margin-bottom: 6px;
  min-height: 80px;

  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.3s;
`;

export const image = css`
  margin-top: 12px;
`;

export const link = css`
  text-decoration: underline;
`;
