import { css, keyframes } from '@emotion/core';

const articleFadeIn = keyframes`
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const articleFadeInStart = css`
  transform: translate3d(0, 12px, 0);
  opacity: 0;
`;

const wrapper = css`
  max-width: 640px;
  margin: 0 auto;
`;

const visual = css`
  ${articleFadeInStart}
  animation: ${articleFadeIn} 0.4s 0.4s ease-out forwards;

  @media screen and (min-width: 64em) {
    margin: 0 -100px;
  }
`;

const header = css`
  ${articleFadeInStart}
  animation: ${articleFadeIn} 0.4s 0.05s ease-out forwards;
  margin-bottom: 5vh;
`;

const headerTop = css``;

const headerTitle = css`
  margin-bottom: 24px;
`;

const headerLink = css``;

const categoryLinkText = css`
  display: inline-block;
  transition: transform 0.3s ease-out;
`;

const categoryLink = css`
  position: relative;
  display: inline-flex;
  padding: 12px 4px;

  &:hover,
  &:focus {
    & span {
      transform: translateX(32px);
    }

    &:after {
      color: hsla(0, 0%, 0%, 0.9);
      transform: translateX(32px);
    }
  }

  &:after {
    content: '⟶';
    position: relative;
    top: -1px;
    margin-left: 8px;
    color: hsla(0, 0%, 0%, 0.3);
    transition: all 0.4s ease-out;
  }
`;

const meta = css`
  ${articleFadeInStart}
  animation: ${articleFadeIn} 0.4s 0.8s ease-out forwards;
  border-top: 1px solid hsla(0, 0%, 0%, 0.1);
  padding-top: 5vh;
  margin-top: 5vh;
`;

const content = css`
  ${articleFadeInStart}
  animation: ${articleFadeIn} 0.4s 0.6s ease-out forwards;
  margin-top: 5vh;
  line-height: 28px;
`;

const tagsWrapper = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  opacity: 0.6;
`;

const tag = css`
  color: black;
  margin-right: 8px;
`;

const goback = css`
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.3s;
  font-size: 24px;
  font-family: var(--ff-sans);
  font-weight: 700;
  letter-spacing: -0.03em;

  &:hover,
  &:focus {
    background-size: 100% 2px;
  }
`;

export const articlepage = {
  wrapper,
  visual,
  header,
  headerTop,
  headerTitle,
  headerLink,
  categoryLink,
  categoryLinkText,
  meta,
  content,
  tagsWrapper,
  tag,
  goback,
};
