/* eslint-disable react-hooks/exhaustive-deps */
import { getApps, initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import React, { useEffect, useState } from 'react';

export const FirebaseProvider: React.FC = ({ children }) => {
  const [isFirebaseLoaded, setIsFirebaseLoaded] = useState(false);
  const isDev = process.env.NODE_ENV === 'development';

  const firebaseConfig = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
  };

  useEffect(() => {
    if (!getApps().length) {
      initializeApp(firebaseConfig);
    }
    const functions = getFunctions();
    const firestore = getFirestore();
    if (isDev) {
      connectFunctionsEmulator(functions, 'localhost', 5001);
      connectFirestoreEmulator(firestore, 'localhost', 8080);
    }
    setIsFirebaseLoaded(true);
  }, []);

  if (!isFirebaseLoaded) {
    return <></>;
  }

  return <>{children}</>;
};
