import { css } from '@emotion/core';

export const globals = css`
  :root {
    --color-primary: blue;
    --color-secondary: red;

    --color-warning: red;
    --color-success: red;
    --color-note: red;

    --color-text: hsla(0, 0%, 0%, 0.7);
    --color-bg: white;
    --color-bg-alt: hsla(0, 0%, 0%, 0.9);
  }

  :root {
    --gutter: 40px;
  }

  :root {
    --ff-sans: Space Grotesk, -apple-system, BlinkMacSystemFont, Segoe UI,
      Helvetica, Arial, sans-serif;
    --ff-serif: Ortica, Times, Georgia, serif;
  }

  @font-face {
    font-family: 'Ortica';
    src: url(${require('../assets/fonts/Ortica-Light.woff2')}) format('woff2'),
      url(${require('../assets/fonts/Ortica-Light.woff2')}) format('woff');
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'Space Grotesk';
    src: url(${require('../assets/fonts/SpaceGrotesk-Regular.woff2')})
        format('woff2'),
      url(${require('../assets/fonts/SpaceGrotesk-Regular.woff')})
        format('woff');
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Space Grotesk';
    src: url(${require('../assets/fonts/SpaceGrotesk-Bold.woff2')})
        format('woff2'),
      url(${require('../assets/fonts/SpaceGrotesk-Bold.woff')}) format('woff');
    font-weight: 700;
    font-display: swap;
  }

  html {
    box-sizing: border-box;
    background: var(--color-bg-alt);
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  ::-moz-selection {
    background: hsla(0, 0%, 0%, 0.3);
  }

  ::selection {
    background: hsla(0, 0%, 0%, 0.3);
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: var(--ff-sans);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: var(--color-text);
    background: var(--color-bg);
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 300;
  }

  p {
    margin: 0;
  }

  h1,
  h2,
  h3 {
    font-family: var(--ff-serif);
    font-weight: 300;
  }

  a {
    color: hsla(0, 0%, 0%, 1);
    text-decoration: none;

    &:focus {
      outline: none;
    }
  }

  strong {
    font-weight: 700;
  }
`;
