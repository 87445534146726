import { Link } from 'gatsby';
import React from 'react';
import { headerStyles } from 'style';
import * as utils from 'style/utils';

interface Props {
  siteTitle: string;
}

export const Header: React.FC<Props> = ({ siteTitle }) => (
  <header css={headerStyles.header}>
    <div css={utils.wrapper}>
      <div>
        <h1>
          <Link to="/" css={headerStyles.link}>
            {siteTitle}
          </Link>
        </h1>
      </div>
    </div>
  </header>
);
